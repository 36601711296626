export const images = [
  {
    imgURL:
      "https://images.pexels.com/photos/461198/pexels-photo-461198.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    imgAlt: "img-1",
  },
  {
    imgURL:
      "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    imgAlt: "img-2",
  },
  {
    imgURL:
      "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    imgAlt: "img-3",
  },
  {
    imgURL:
      "https://images.pexels.com/photos/54455/cook-food-kitchen-eat-54455.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
    imgAlt: "img-4",
  },
];

export const student = {
  name: "John Doe",
  registrationNo: "123456",
  dob: "01/01/2000",
  dateOfAdmission: "01/09/2020",
  fatherName: "Richard Doe",
  courses: "Mathematics",
  profilePic:
    "https://images.unsplash.com/photo-1457449940276-e8deed18bfff?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  certficatePic:
    "https://images.unsplash.com/photo-1584445584400-1a7cc5de77ae?q=80&w=1960&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
};

//About us

export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Online Courses",
    desc: "We offer training in various fields such as Data Analysis, Data Science, Account Maintenance, and Web Development to prepare students for diverse sectors.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificate",
    desc: "Our courses provide essential computer education at affordable fees, especially for lower and middle-class students.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Why Choose Us",
    desc: "Our courses provide professional qualifications, career orientation, and self-development. Students' performance is monitored through tests to ensure skill development. Our globally recognized certificates help graduates secure jobs in reputed organizations across India. We are committed to quality education by expert trainers, empowering rural youth.",
  },
];

//spinning ammount

export const awrapper = [
  {
    cover:
      "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "100",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover:
      "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "100",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
];

//course card
export const coursesCard = [
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "HTML, CSS, and Javascript for Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Satyapir Pradhan",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
];

//online course
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
];

//students image
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
];

//Price of your packages
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
];
// faqs for pricing packages

export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
];

// blg changed to director sms

export const blog = [
  {
    cover:
      "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Replace with actual image URL
    title: "Build your Dream Software & Engineering Career",
  },
];

//Student reqvew

export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
];

//notice
export const notices = [
  {
    id: 1,
    title: "Introduction: A sentence introducing the purpose of the notice.",
    date: "2024-06-22",
  },
  {
    id: 2,
    title: "The Green Valley School is excited to announce the Annual Science",
    date: "2024-06-21",
  },
  {
    id: 3,
    title:
      "Interested students must register with their science teachers by March 5, 2024.",
    date: "2024-06-20",
  },
  {
    id: 4,
    title: "All projects must adhere to the theme and be original work.",
    date: "2024-06-19",
  },
  {
    id: 5,
    title:
      "Projects can be submitted individually or in teams of up to three members.",
    date: "2024-06-18",
  },
];

// Dummy data for images
export const gallery = [
  {
    id: 1,
    title: "Image 1",
    category: 'cat',
    url: "/images/photos/image_1.jpg",
    coverImage: "/images/photos/image_1.jpg"
  },
  {
    id: 1,
    title: "Image 1",
    category: 'dog',
    url: "/images/photos/image_1.jpg",
    coverImage: "/images/photos/image_1.jphgh"
  },
    // Add more items as needed
];


